<template>
  <!-- 抽盒商品 -->
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div>
<!--          <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450" placeholder="请输入"
            v-model="retrieveForm.searchValue">
            <el-button slot="append" type="primary" icon="el-icon-search" @click="submitSearch"></el-button>
          </el-input> -->
        </div>
        <div class="flex flex-ac">
          <div class="fs-16 fc-6b9eff pointer" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
        </div>
      </div>
<!--      <div class="retrieveDiv flex flex-ac">
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20">轮播图名称：</span>
          <el-input class="w-250" v-model="retrieveForm.slideName" clearable></el-input>
        </div>
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20">系列名称：</span>
          <el-input class="w-250" v-model="retrieveForm.seriesName" clearable></el-input>
        </div>
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20">类型：</span>
          <el-select class="w-250" v-model="retrieveForm.boxType" clearable placeholder="请选择">
            <el-option v-for="item in boxTypes" :key="item.value" :label="item.key" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div>
          <el-button type="primary" @click="submitSearch">查询</el-button>
        </div>
      </div> -->
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
        <template v-slot:img="scope">
          <img :src="scope.row.imgUrl" alt="" class="tableImg" v-if="scope.row.imgUrl">
        </template>
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
    </TableHeightFull>
    <!-- 新增修改弹框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" top="20vh" width="30%" @close="resetForm">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="商品类型" prop="goodsType" v-if="'goodsType' in ruleForm"
          :rules="{required: true, message: '请至少选择一个商品类型', trigger: 'change'}">
          <el-select v-model="ruleForm.goodsType" placeholder="请选择" @change="handleBoxChang">
            <el-option v-for="item in boxTypes" :key="item.value" :label="item.key" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="系列名称" prop="seriesId" 
          :rules="{required: true, message: '请至少选择一个商品系列', trigger: 'change'}">
          <el-select v-model="ruleForm.seriesId" filterable placeholder="请选择" @change="handleSeriesChang">
            <el-option v-for="item in seriesList" :key="item.id" :label="item.seriesName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="显示数量" prop="showDataTotal" v-if="'showDataTotal' in ruleForm">
          <el-input type="number" v-model="ruleForm.showDataTotal"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
import { mapState } from 'vuex'
export default {
  name: 'draws',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "CountDown": (resolve) => require(["@/components/CountDown"], resolve),
    "UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
  },
  computed: {
    ...mapState('menu', {
      'boxTypes': state => state.dictList ? state.dictList.box_type : []
    })
  },
  data () {
    return {
      retrieveForm: {
        searchValue: '',
        slideName: '',
        seriesName: '',
        boxType: ''
      },
      butLoading: false,
      tabLoading: false,
      columns: [
        { type: 'index', label: '序号', width: '60', align: 'center', index: this.indexMethod },
        { label: '系列名称', prop: 'seriesName', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'fun', label: '系列类型', prop: 'goodsType', align: 'left', 'show-overflow-tooltip': true, formatData: (e) => {
            let ele = this.boxTypes.find(ele => ele.value == e)
            return ele ? ele.key : e
          }
        },
				{ label: '显示数量', prop: 'showDataTotal', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '120',
          buttons: [
            { type: 'text', text: '编辑', fn: this.editList, },
            { type: 'text', text: '删除', fn: this.deleteList, }
          ],
        },
      ],
      tableData: [],
      //弹框
      dialogFormVisible: false,
      dialogTitle: '新增',
      ruleForm: {
        goodsType: 3,//盒子类型
        // seriesName: '', //系列名称
        seriesId: '',//系列Id
				showDataTotal:'',//商品显示数量
        id: '', 
      },
      seriesList: [],//商品系列列表
    }
  },
  mounted () {
    this.getTableData()
  },
  methods: {
    //查询
    submitSearch () {
      this.pageInfo.pageNum = 1
      this.getTableData()
    },
    //导出
    exportExcel () { },
    //获取表格数据
    getTableData () {
      this.tabLoading = true
      this.$http.get('/showDataSeries/findShowDataSeriesList', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          // slideName: this.retrieveForm.slideName,
          // seriesName: this.retrieveForm.seriesName,
          // boxType: this.retrieveForm.boxType
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      })
    },
    //获取商品系列列表
    handleBoxChang (even) {
      console.log(even)
      if (even == 3) {
        this.$http.get('/boxSeries/findBoxSeriesAll').then((result) => {
          this.seriesList = result.data.data
        })
      } else if (even == 5) {
        this.$http.get('/goodSeries/finGoodSeriesAll').then((result) => {
          this.seriesList = result.data.data
        })
      }
    },
    //选择系列
    handleSeriesChang (even) {
      console.log(even)
      this.ruleForm.seriesName = this.seriesList.filter(ele => ele.id == even)[0].seriesName
    },
    //新增列表
    addList () {
      this.dialogTitle = '新增'
      this.dialogFormVisible = true
      this.ruleForm.sort = this.tableData.length > 0 ? this.tableData[0].sort + 1 : 1  //排序
      this.handleBoxChang(3)
    },
    //编辑列表
    editList (row) {
      this.dialogTitle = '编辑'
      this.dialogFormVisible = true
      this.ruleForm = JSON.parse(JSON.stringify(row))   //排序
      this.handleBoxChang(3)
    },
    //删除
    deleteList (row) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/showDataSeries/removeShowDataSeriesById', { id: row.id }).then(({ data: result }) => {
          this.getTableData()
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        })
      }).catch(_ => { })
    },
    //确认弹框
    affirmPopup () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.butLoading = true
          let apiUrl = ''
          if (this.dialogTitle == '新增') {
            apiUrl = '/showDataSeries/addShowDataSeries'
          } else if (this.dialogTitle == '编辑') {
            apiUrl = '/showDataSeries/editShowDataSeries'
          }
          this.$http.post(apiUrl, this.ruleForm).then((result) => {
            this.dialogFormVisible = false
            this.getTableData()
            this.$message({
              message: this.dialogTitle + '成功',
              type: 'success'
            })
            this.butLoading = false
          }).catch(err => {
            this.butLoading = false
          })
        }
      })
    },
    //重置清除校验
    resetForm () {
      Object.assign(
        this.ruleForm,
        this.$options.data().ruleForm
      )
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },
    //表格跳转详情
    headerRowClick (row) {
      console.log(row)
    },
  },
}
</script>

<style lang="less" scoped>
</style>